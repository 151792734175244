import { useSafeTimeout } from "@sugarliving/use-safe-timeout";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Video from "twilio-video";
import Sentry from "helpers/sentry";

type TwilioAccessToken = {
  jti: string;
  iss: string;
  sub: string;
  nbf: number;
  exp: number;
  grants: {
    identity: string;
    video: {
      room: string;
    };
  };
};

export enum ConnectionStatus {
  IDLE = "idle",
  CONNECTING = "connecting",
  CONNECTED = "connected",
  RECONNECTING = "reconnecting",
  DISCONNECTED = "disconnected",
  ERROR = "error",
}

const DEFAULT_NO_ANSWER_DELAY_MS = 60000;

type UseRoomOptions = {
  noAnswerDelayMs: number;
};

const useRoom = (
  localTracks: (Video.LocalAudioTrack | Video.LocalVideoTrack)[],
  options: Partial<UseRoomOptions> = {}
) => {
  const setSafeTimeout = useSafeTimeout();

  const roomRef = useRef<Video.Room>();
  const [error, setError] = useState<Error | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.IDLE);
  const [remoteParticipant, setRemoteParticipant] = useState<Video.RemoteParticipant | null>(null);
  const [remoteUnavailable, setRemoteUnavailable] = useState(false);

  const { noAnswerDelayMs = DEFAULT_NO_ANSWER_DELAY_MS } = options;

  const participantDisconnected = (participant: Video.RemoteParticipant) => {
    setRemoteParticipant(null);
    setConnectionStatus(ConnectionStatus.DISCONNECTED);
  };

  const participantConnected = (participant: Video.RemoteParticipant) => {
    setRemoteParticipant(participant);
    setConnectionStatus(ConnectionStatus.CONNECTED);
    setRemoteUnavailable(false);
  };

  useEffect(() => {
    let cancelTimeout = () => {
      /* noop */
    };

    if (connectionStatus === ConnectionStatus.CONNECTING) {
      cancelTimeout = setSafeTimeout(() => setRemoteUnavailable(true), noAnswerDelayMs);
    }

    return cancelTimeout;
  }, [connectionStatus, noAnswerDelayMs, setSafeTimeout]);

  const setConnected = () => setConnectionStatus(ConnectionStatus.CONNECTED);
  const setReconnecting = () => setConnectionStatus(ConnectionStatus.RECONNECTING);

  const connect = useCallback(
    async (token: string) => {
      if (!localTracks || localTracks.length < 1) return;

      try {
        const payload = jwt_decode<TwilioAccessToken>(token);
        const roomName = payload.grants.video.room;

        setConnectionStatus(ConnectionStatus.CONNECTING);
        setError(null);

        const isPixel = navigator.userAgent.indexOf("Pixel");
        const preferredVideoCodecs = isPixel ? ["H264" as Video.VideoCodec] : [];

        const room = await Video.connect(token, {
          name: roomName,
          insights: false,
          tracks: localTracks,
          preferredVideoCodecs,
        });

        roomRef.current = room;

        const onDisconnect = () => {
          setConnectionStatus(ConnectionStatus.DISCONNECTED);

          room.off("participantConnected", participantConnected);
          room.off("participantDisconnected", participantDisconnected);
          room.off("participantReconnected", participantConnected);
          room.off("reconnecting", setReconnecting);
          room.off("reconnected", setConnected);
          room.disconnect();
        };

        // add participant already on the call
        room.participants.forEach(participantConnected);

        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.on("participantReconnected", participantConnected);
        room.on("reconnecting", setReconnecting);
        room.on("reconnected", setConnected);

        room.once("disconnected", onDisconnect);
      } catch (err) {
        setError(err);
        setConnectionStatus(ConnectionStatus.ERROR);
        Sentry.captureException(err);
      }
    },
    [localTracks]
  );

  return useMemo(
    () => ({
      /* fns */
      connect,
      hangUp: () => roomRef.current?.disconnect(),
      /* statuses */
      status: connectionStatus,
      connecting: connectionStatus === ConnectionStatus.CONNECTING,
      connected: connectionStatus === ConnectionStatus.CONNECTED,
      disconnected: connectionStatus === ConnectionStatus.DISCONNECTED,
      error: connectionStatus === ConnectionStatus.ERROR && error,
      reconnecting: connectionStatus === ConnectionStatus.RECONNECTING,
      remoteUnavailable,
      /* objects */
      remoteParticipant,
      room: roomRef.current,
    }),
    [connect, connectionStatus, error, remoteParticipant, remoteUnavailable]
  );
};

export default useRoom;
