import { ApolloError, ApolloClient } from "@sugarliving/apollo";
import gql from "graphql-tag";
import { CurrentUser } from "./__generated__/CurrentUser";
import { useEnhancedQuery } from "hooks/apollo";

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser: me {
      id
      email
      firstName
      lastName
      phoneNumber

      profile {
        id
        preferredFirstName
        preferredLastName
        profileImage {
          id
          url
        }
        building {
          id
          name
          propertyManagementSoftware
          propertyManagementRentPortal
          propertyManagementMaintenancePortal
        }
      }
    }
  }
`;

interface UseCurrentUserOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client?: ApolloClient<any>;
}

const useCurrentUser = ({ client }: UseCurrentUserOptions = {}): {
  data?: CurrentUser;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useEnhancedQuery<CurrentUser>(CURRENT_USER_QUERY, {
    client,
  });

  return {
    data,
    loading,
    error,
  };
};

export type { CurrentUser };

export default useCurrentUser;
