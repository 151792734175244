import cx from "classnames";
import React, { useEffect } from "react";

import styles from "./Page.module.css";
import Navbar from "components/common/Navbar";

export interface PageProps {
  header?: React.ReactNode;
  title?: string;
  fullBg?: boolean;
  color?: "primary" | "danger";
  className?: string;
}

const Page: React.FC<PageProps> = ({
  header,
  title,
  fullBg,
  children,
  className,
  color = "primary",
}) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }

    return () => {
      document.title = "Sugar Living";
    };
  }, [title]);

  return (
    <div className={cx(fullBg && styles.fullBg, styles[color], className)}>
      <header className={styles.header}>
        <Navbar color={color} />
        {header && <div className={styles.headerContent}>{header}</div>}
      </header>
      <div className={cx(styles.content, styles[color])}>{children}</div>
    </div>
  );
};

export default Page;
