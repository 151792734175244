import cx from "classnames";
import React from "react";
import styles from "./VideoCallButton.module.css";

export interface VideoCallButtonProps {
  className?: string;
  color: "green" | "red";
  children: React.ReactNode;
  label: string;
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

const VideoCallButton = ({ className, children, color, label, onClick }: VideoCallButtonProps) => {
  return (
    <button
      className={cx(
        styles.root,
        {
          [styles.green]: color === "green",
          [styles.red]: color === "red",
        },
        className
      )}
      type="button"
      aria-label={label}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default VideoCallButton;
