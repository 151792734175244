import cx from "classnames";
import React, { useRef, useEffect } from "react";
import { Track, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import useMediaStreamTrack from "./hooks/useMediaStreamTrack";

type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

interface VideoTrackProps {
  className?: string;
  videoClassName?: string;
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRemoteVideoTrack = (variableToCheck: any): variableToCheck is RemoteVideoTrack => {
  return typeof variableToCheck?.setPriority !== "undefined";
};

export default function VideoTrack({
  className,
  videoClassName,
  track,
  isLocal,
  priority,
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement | null>(null);
  const mediaStreamTrack = useMediaStreamTrack(track);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const el = ref.current;

    if (el) {
      el.muted = true;

      if (isRemoteVideoTrack(track) && priority) {
        track.setPriority(priority);
      }
      track.attach(el);

      return () => {
        track.detach(el);
        if (isRemoteVideoTrack(track) && priority) {
          // Passing `null` to setPriority will set the track's priority to that which it was published with.
          track.setPriority(null);
        }
      };
    }
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== "environment";
  const style = {
    transform: isLocal && isFrontFacing ? "rotateY(180deg)" : "",
    objectFit: "cover" as const,
  };

  // we won't be able to show captions on a live video call
  /* eslint-disable jsx-a11y/media-has-caption, jsx-a11y/control-has-associated-label */
  return (
    <div className={className}>
      <video ref={ref} className={cx("h-full w-full", videoClassName)} style={style} />
    </div>
  );
  /* eslint-enable jsx-a11y/media-has-caption, jsx-a11y/control-has-associated-label */
}
