import cx from "classnames";
import React from "react";
import styles from "./AspectRatio.module.css";

export interface RatioProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  ratio: number;
  ratioClassName?: string;
  style?: React.CSSProperties;
}

const Ratio = ({
  children,
  className,
  contentClassName,
  ratio,
  ratioClassName,
  style,
}: RatioProps) => {
  const paddingTop = ratio === 0 ? 100 : 100 / ratio;

  return (
    <div className={cx(styles.root, className)} style={style}>
      <div
        className={cx(styles.ratio, ratioClassName)}
        style={{
          paddingTop: `${paddingTop}%`,
        }}
      >
        <div className={cx(styles.content, contentClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default Ratio;
