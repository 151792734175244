import cx from "classnames";
import React from "react";
import * as yup from "yup";
import {
  RequestVisitorAccess,
  RequestVisitorAccessVariables,
} from "../Directory/__generated__/RequestVisitorAccess";
import { REQUEST_VISITOR_ACCESS } from "../Directory/queries";
import styles from "./VisitorAccess.module.css";
import { VisitorAccess_visitorAccess as VisitorAccessDataType } from "./__generated__/VisitorAccess";
import { ReactComponent as CircleCheckIcon } from "assets/svg/circle-checkmark.svg";
import { ReactComponent as PersonAddIcon } from "assets/svg/person-add.svg";
import { EnhancedFormik, Form, InputField, SubmitButton, FormStatus } from "components/common/Form";
import Page from "components/common/Page";
import { useEnhancedMutation } from "hooks/apollo";
import { safeJoinWithSpace } from "uplift-core";

interface FormValues {
  visitorName: string;
}

interface VisitorAccessRequestProps {
  visitorAccessData: VisitorAccessDataType;
}

const requestAccessSchema = yup
  .object()
  .shape({ visitorName: yup.string().min(3).required().label("Name") });

const VisitorAccessRequest: React.FC<VisitorAccessRequestProps> = ({ visitorAccessData }) => {
  const [requestVisitorAccess] = useEnhancedMutation<
    RequestVisitorAccess,
    RequestVisitorAccessVariables
  >(REQUEST_VISITOR_ACCESS, undefined, { auth: false });

  const { inviter, doorUuid } = visitorAccessData;

  return (
    <Page fullBg color="danger">
      <EnhancedFormik<FormValues>
        initialValues={{ visitorName: "" }}
        validationSchema={requestAccessSchema}
        validateOnBlur={false}
        onSubmit={async ({ visitorName }, { setFormSuccess }) => {
          const { data } = await requestVisitorAccess({
            variables: { doorUuid, profileId: inviter.id, visitorName },
          });
          if (data?.requestVisitorAccess?.success) {
            setFormSuccess("A request was sent for access.");
          } else {
            throw new Error(
              data?.requestVisitorAccess?.message || "Failed to request access, please try again."
            );
          }
        }}
      >
        {({ status }) => (
          <div className={styles.contentContainer}>
            <div>
              <div className={cx(styles.infoText, styles.infoTextHero)}>Key expired</div>
              <div className={styles.infoText}>This one-time key has already been used.</div>
            </div>

            <div className={styles.requestAccessForm}>
              {status.formError && <FormStatus status={status} className="mt-0" />}
              {status.formSuccess ? (
                <>
                  <div className={styles.requestAccessSuccess}>
                    <CircleCheckIcon className={cx(styles.icon, styles.requestAccessSuccessIcon)} />
                    <span className="ml-2">Request Sent</span>
                  </div>
                  <div className="text-lg mt-3">{status.formSuccess}</div>
                </>
              ) : (
                <>
                  <div className="text-lg">
                    If you still need access, ask{" "}
                    <strong>
                      {safeJoinWithSpace(inviter.preferredFirstName, inviter.preferredLastName)}
                    </strong>{" "}
                    for another key.
                  </div>

                  <Form className="mt-3">
                    <InputField name="visitorName" label="Name" className="rounded-lg" />
                    <SubmitButton className={styles.requestAccessFormSubmitButton}>
                      <PersonAddIcon height={24} width={24} className="inline" />
                      <span className="ml-2">Request Access</span>
                    </SubmitButton>
                  </Form>
                </>
              )}
            </div>
          </div>
        )}
      </EnhancedFormik>
    </Page>
  );
};

export default VisitorAccessRequest;
