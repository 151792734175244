import cx from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import styles from "./Navbar.module.css";

import { ReactComponent as BigCloud } from "assets/svg/big-cloud.svg";
import { ReactComponent as LeftCloud } from "assets/svg/left-cloud.svg";
import { ReactComponent as RightCloud } from "assets/svg/right-cloud.svg";
import { HOME_URL, INTERCOM_URL } from "constants/urls";
import { Logo, LogoColors } from "uplift-core";

interface NavbarProps {
  className?: string;
  color?: LogoColors;
}

const Navbar: React.FC<NavbarProps> = ({ className, color = "primary" }) => {
  const location = useLocation();
  const { show } = useIntercom();

  // TODO: fix Intercom on web, might need new intercom page or just use the hook here
  return (
    <div className={cx(styles.root, styles[color], className)}>
      <div className={cx(styles.cloud, styles.leftCloudContainer)}>
        <LeftCloud />
      </div>
      <div className={cx(styles.cloud, styles.rightCloudContainer)}>
        <RightCloud />
      </div>
      <div className={cx(styles.cloud, styles.bigCloudContainer)}>
        <BigCloud />
      </div>

      <Link className={styles.logoContainer} to={HOME_URL}>
        <Logo color={color} />
      </Link>
      <div className={styles.links}>
        {location.pathname !== INTERCOM_URL && (
          <button className={styles.link} onClick={() => show()} type="button">
            Support
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
