import cx from "classnames";
import React from "react";
import Loader from "../Loader";
import { ModalProps, OurModal, OurModalProps } from "./Modal";
import styles from "./Modal.module.css";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import Button from "components/common/Button";

const OurBottomSheet: React.FC<OurModalProps> = props => (
  <OurModal className={styles.bottomSheetModal} {...props} />
);

export interface BottomSheetModalProps extends Omit<ModalProps, "title"> {
  callToActionButtonClassName?: string;
  callToActionButtonText: string;
  callToActionOnClick: () => void;
  callToActionDisabled?: boolean;
  callToActionLoading?: boolean;
  hideCallToActionDisclosureChevon?: boolean;
}

export const BottomSheetModal: React.FC<BottomSheetModalProps> = ({
  isOpen,
  onRequestClose,
  onAfterClose,
  type,
  children,
  callToActionButtonClassName,
  callToActionButtonText,
  callToActionOnClick,
  callToActionDisabled,
  callToActionLoading,
  hideCallToActionDisclosureChevon,
  className,
}) => (
  <OurBottomSheet
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    onAfterClose={onAfterClose}
    className={className}
  >
    <div className={styles.bottomSheetRoot}>
      <div className={styles.modalContent}>{children}</div>
      <Button
        className={cx(styles.bottomSheetCallToAction, callToActionButtonClassName)}
        disabled={callToActionDisabled || callToActionLoading}
        loading={callToActionLoading}
        onClick={callToActionOnClick}
        type="submit"
      >
        {callToActionButtonText}
        {!hideCallToActionDisclosureChevon && (
          <div className={styles.bottomSheetDisclosureChevron}>
            {callToActionLoading ? (
              <Loader size="small" className="mx-0 px-0" lineClassName="bg-white" message={null} />
            ) : (
              <ChevronRight height={10} />
            )}
          </div>
        )}
      </Button>
    </div>
  </OurBottomSheet>
);
