import { useEffect } from "react";
import { useUID } from "react-uid";

type IntercomCallbacksMap = { [key: string]: () => void };

const onHideCallbacks: IntercomCallbacksMap = {};
const onShowCallbacks: IntercomCallbacksMap = {};

export const onHideIntercom = () => {
  Object.values(onHideCallbacks).forEach(onHide => onHide());
};
export const onShowIntercom = () => {
  Object.values(onShowCallbacks).forEach(onShow => onShow());
};

export const useOnHideIntercom = (cb: () => void) => {
  const uid = useUID();
  useEffect(() => {
    onHideCallbacks[uid] = cb;
    return () => {
      delete onHideCallbacks[uid];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useOnShowIntercom = (cb: () => void) => {
  const uid = useUID();
  useEffect(() => {
    onShowCallbacks[uid] = cb;
    return () => {
      delete onShowCallbacks[uid];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
