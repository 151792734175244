import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import Room from "./Room";
import styles from "./Room.module.css";
import { VideoCallProvider } from "./VideoCallProvider";
import { useDevices } from "./hooks/useDevices";
import { UnstyledButton } from "components/common/Button";
import Loader from "components/common/Loader";
import { useModalState, Modal } from "components/common/Modal";
import SpaceBetween from "components/common/SpaceBetween";
import Text from "components/common/Text";
import { useWebviewContext } from "uplift-core";

interface VideoChatParams {
  token: string;
}

const VisitorAccess: React.FC<RouteComponentProps<VideoChatParams>> = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const isInWebview = useWebviewContext();
  const { modalProps, modalState, setModalStateAndVisible } = useModalState<Error | null>(null);
  const { error: devicesError, hasDevices } = useDevices();

  const handleError = useCallback(
    (err: Error) => {
      err && setModalStateAndVisible(err);
    },
    [setModalStateAndVisible]
  );

  const renderContent = () => {
    if (hasDevices) {
      return <Room token={token} onError={handleError} />;
    }

    if (devicesError) {
      return (
        <div className={styles.centeredContent}>
          <SpaceBetween className="mt-4">
            <Text size="lg" weight="semibold">
              Video calling is not supported on this device
            </Text>
            {!isInWebview && (
              <UnstyledButton onClick={() => history.goBack()}>
                <Text weight="medium">Go back</Text>
              </UnstyledButton>
            )}
          </SpaceBetween>
        </div>
      );
    }

    return (
      <div className={styles.centeredContent}>
        <Loader message="Loading…" />
      </div>
    );
  };

  return (
    <VideoCallProvider>
      <div className={styles.root}>
        {renderContent()}
        <Modal {...modalProps} type="error" message={modalState?.message} />
      </div>
    </VideoCallProvider>
  );
};

export default VisitorAccess;
