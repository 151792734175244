import React from "react";
import { useUserContext } from "uplift-core";

const Home: React.FC = () => {
  const { currentUser } = useUserContext();
  return (
    <div>
      <h1>Hello {currentUser?.id || "stranger"}</h1>
    </div>
  );
};

export default Home;
