import { useSafeTimeout } from "@sugarliving/use-safe-timeout";
import cx from "classnames";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ParticipantTracks from "./ParticipantTracks";
import styles from "./Room.module.css";
import VideoCallButton from "./VideoCallButton";
import { useVideoCallContext, LocalTrackStatus } from "./VideoCallProvider";
import VideoTrack from "./VideoTrack";
import { UseDoorKey, UseDoorKeyVariables } from "./__generated__/UseDoorKey";
import useRoom, { ConnectionStatus } from "./hooks/useRoom";
import { ReactComponent as BackArrowIcon } from "assets/svg/back-arrow.svg";
import { ReactComponent as CircleCheckmark } from "assets/svg/circle-checkmark.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/lock-unlock.svg";
import { ReactComponent as HangUpIcon } from "assets/svg/phone-hangup.svg";
import AspectRatio from "components/common/AspectRatio";
import { UnstyledButton } from "components/common/Button";
import Loader from "components/common/Loader";
import {
  useModal,
  ActionButton,
  Modal,
  FullScreenConfirmationModal,
  useModalState,
} from "components/common/Modal";
import SpaceBetween from "components/common/SpaceBetween";
import Text from "components/common/Text";
import { useEnhancedMutation } from "hooks/apollo";
import { useQuery } from "hooks/router";
import { useCurrentUser, useWebviewContext } from "uplift-core";

const AUTO_CLOSE_SUCCESS_DELAY_MS = 2500;

// noop to return on conditional effects
const noop = () => {
  /* noop */
};

export const USE_DOOR_KEY_MUTATION = gql`
  mutation UseDoorKey($doorId: DoorSmartID!) {
    useDoorKey(doorId: $doorId) {
      success
      message
      data {
        ... on MiwaIntegration {
          basic
          ext1
          ext2
          roomData
          checkIn
          checkOut
        }
      }
    }
  }
`;

interface RoomProps {
  token: string;
  onError: (err: Error) => void;
}

const Room = ({ token, onError }: RoomProps) => {
  const isInWebview = useWebviewContext();
  const { modalProps, setModalVisible } = useModal();
  const {
    modalProps: successModalProps,
    modalState: successModalState,
    setModalStateAndVisible: setSuccessModalState,
    setModalVisible: setSuccessModalVisible,
  } = useModalState<string>("");

  const setSafeTimeout = useSafeTimeout();

  const {
    localTracks,
    localTracksAcquired,
    localTracksStatus,
    videoTrack: localVideoTrack,
    getAudioAndVideoTracks,
    stopAudioAndVideoTracks,
  } = useVideoCallContext();

  const {
    connecting,
    connected,
    disconnected,
    reconnecting,
    status: roomStatus,
    error,
    connect,
    hangUp,
    remoteParticipant,
    remoteUnavailable,
  } = useRoom(localTracks);

  const history = useHistory();
  const query = useQuery();
  const doorId = query.get("doorId");
  const remoteName = query.get("remoteName") || "Tenant";
  const currentUser = useCurrentUser();

  const [doorKeyMutation] = useEnhancedMutation<UseDoorKey, UseDoorKeyVariables>(
    USE_DOOR_KEY_MUTATION
  );

  // TODO: put into useModalState/useModal hooks
  useEffect(() => {
    // Automatically close success modal after a few seconds
    if (!successModalProps.isOpen) return noop;

    return setSafeTimeout(() => {
      setSuccessModalVisible(false);
    }, AUTO_CLOSE_SUCCESS_DELAY_MS);
  }, [successModalProps.isOpen, setSuccessModalVisible, setSafeTimeout]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  useEffect(() => {
    if (disconnected || error) {
      stopAudioAndVideoTracks();
      return;
    }

    if (!connected && localTracksStatus === LocalTrackStatus.IDLE) {
      getAudioAndVideoTracks();
    }
  }, [
    connected,
    disconnected,
    error,
    getAudioAndVideoTracks,
    localTracksStatus,
    stopAudioAndVideoTracks,
  ]);

  useEffect(() => {
    if (token && localTracksAcquired && roomStatus === ConnectionStatus.IDLE) {
      connect(token);
    }
  }, [connect, localTracksAcquired, roomStatus, token]);

  const isTenant = currentUser.data?.currentUser && doorId;

  const handleUnlockDoor = async () => {
    setModalVisible(false);

    try {
      if (!isTenant || !doorId) {
        throw new Error("You do not have permissions to unlock the door.");
      }

      const result = await doorKeyMutation({ variables: { doorId } });

      if (result.data?.useDoorKey?.success) {
        setSuccessModalState(result.data.useDoorKey.message || "Door Unlocked Successfully");
      } else {
        throw new Error(
          result.data?.useDoorKey?.message || "Encountered an error while unlocking the door"
        );
      }
    } catch (err) {
      onError(err);
    }
  };

  if (disconnected || remoteUnavailable || error) {
    return (
      <>
        {!isInWebview && (
          <div className={styles.topLeftContent}>
            <UnstyledButton onClick={() => history.goBack()}>
              <BackArrowIcon height="24" width="24" />
            </UnstyledButton>
          </div>
        )}
        <div className={styles.centeredContent}>
          <SpaceBetween className="mt-4">
            <Text size="2xl" weight="semibold">
              Call ended
            </Text>
            {remoteUnavailable && (
              <Text size="lg" weight="medium">
                {remoteName} is not available.
              </Text>
            )}
          </SpaceBetween>
        </div>
      </>
    );
  }

  return (
    <div className={styles.videoRoom}>
      {(connecting || reconnecting) && (
        <div className={styles.centeredContent}>
          <Loader message={reconnecting ? "Reconnecting…" : `Calling ${remoteName}…`} />
        </div>
      )}
      {connected && (
        <>
          {remoteName && (
            <div className={styles.header}>
              <Text size="2xl" color="white" align="center">
                {remoteName}
              </Text>
            </div>
          )}
          {remoteParticipant && (
            <ParticipantTracks
              key={remoteParticipant.identity}
              className={styles.remoteVideo}
              participant={remoteParticipant}
            />
          )}
        </>
      )}
      {localVideoTrack && (
        <div className={cx(styles.topLeftContent, styles.localVideoTrackContainer)}>
          <AspectRatio ratio={2 / 3}>
            <VideoTrack
              className={styles.localVideoTrack}
              videoClassName={styles.localVideo}
              track={localVideoTrack}
              isLocal
            />
          </AspectRatio>
        </div>
      )}
      <div className={styles.buttonBar}>
        <SpaceBetween className="ml-16">
          {isTenant && connected && (
            <VideoCallButton
              color="green"
              label="Unlock Door"
              onClick={() => setModalVisible(true)}
            >
              <UnlockIcon height="24" width="24" />
            </VideoCallButton>
          )}
          <VideoCallButton color="red" label="Hang up" onClick={hangUp}>
            <HangUpIcon height="24" width="24" />
          </VideoCallButton>
        </SpaceBetween>
      </div>

      <Modal
        {...modalProps}
        title="Unlock Door?"
        message={`Are you sure that you want to unlock the door for ${remoteName}?`}
        closeButtonText="No, Cancel"
        actions={[
          <ActionButton key="unlock" onClick={handleUnlockDoor}>
            Unlock Door
          </ActionButton>,
        ]}
      />

      <FullScreenConfirmationModal
        {...successModalProps}
        translucent
        color="success"
        message={successModalState}
        icon={<CircleCheckmark height={100} width={100} />}
      />
    </div>
  );
};

export default Room;
