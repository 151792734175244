import { makeUrl } from "@sugarliving/strings";
import { useSafeTimeout } from "@sugarliving/use-safe-timeout";
import { matchSorter } from "match-sorter";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";
import { useDevices } from "../VideoChat/hooks/useDevices";
import styles from "./BuildingDoorDirectory.module.css";
import SearchField from "./SearchField";
import {
  DoorDirectory,
  DoorDirectoryVariables,
  DoorDirectory_directory_directoryProfiles as DirectoryProfile,
} from "./__generated__/DoorDirectory";
import { RequestVideoChat, RequestVideoChatVariables } from "./__generated__/RequestVideoChat";
import {
  RequestVisitorAccess,
  RequestVisitorAccessVariables,
} from "./__generated__/RequestVisitorAccess";
import { DOOR_DIRECTORY_QUERY, REQUEST_VIDEO_CHAT, REQUEST_VISITOR_ACCESS } from "./queries";
import { BuildingDirectoryDisplayFormat } from "__generated__/globalTypes";
import { ReactComponent as CircleCheckmark } from "assets/svg/circle-checkmark.svg";
import { ReactComponent as RequestDoorAccessIcon } from "assets/svg/request-door-access.svg";
import { ReactComponent as VideoCallIcon } from "assets/svg/video-call.svg";
import { IconButton } from "components/common/Button";
import { EnhancedFormik, Form, InputField } from "components/common/Form";
import Loader from "components/common/Loader";
import {
  BottomSheetModal,
  BottomSheetModalProps,
  FullScreenConfirmationModal,
  useModalState,
} from "components/common/Modal";
import Page from "components/common/Page";
import Row from "components/common/Row";
import SpaceBetween from "components/common/SpaceBetween";
import Text from "components/common/Text";
import { VIDEO_CHAT_URL } from "constants/urls";
import { useEnhancedMutation, useEnhancedQuery } from "hooks/apollo";
import { safeJoin } from "uplift-core";

const safeJoinWithComma = safeJoin(", ");
const safeJoinInitials = safeJoin("");

const matchSorterConfig = {
  keys: ["publicFirstName", "publicLastName", "unitName"],
};

interface AvatarProps {
  imgAlt: string;
  initials: string;
  profileImageUrl?: string | null;
}

const Avatar: React.FC<AvatarProps> = ({ initials, imgAlt, profileImageUrl }) => {
  return (
    <div className={styles.avatar}>
      {profileImageUrl ? (
        <img alt={imgAlt} src={profileImageUrl} />
      ) : (
        <span className={styles.avatarInitials}>{initials}</span>
      )}
    </div>
  );
};

const requestAccessSchema = yup
  .object()
  .shape({ visitorName: yup.string().min(3).required().label("Visitor Name") });

interface FormValues {
  visitorName: string;
}

interface RequestAccessBottomSheetProps {
  modalProps: Omit<
    BottomSheetModalProps,
    "children" | "callToActionButtonText" | "callToActionOnClick"
  >;
  callToActionButtonText: BottomSheetModalProps["callToActionButtonText"];
  onSubmit: (values: FormValues) => void;
  tenant: DirectoryProfile | null;
  directoryDisplayFormat: BuildingDirectoryDisplayFormat | null;
}

const RequestAccessBottomSheet = ({
  modalProps,
  callToActionButtonText,
  onSubmit,
  tenant,
  directoryDisplayFormat,
}: RequestAccessBottomSheetProps) => {
  const visitorNameInputRef = useRef<HTMLInputElement>(null);
  const setSafeTimeout = useSafeTimeout();

  useEffect(() => {
    return setSafeTimeout(() => {
      if (!tenant) return;
      visitorNameInputRef.current?.focus();
    }, 0);
  }, [setSafeTimeout, tenant]);

  if (!tenant) return null;

  const isMainOffice = tenant.publicFirstName === "Main Office";

  return (
    <EnhancedFormik<FormValues>
      initialValues={{
        visitorName: "",
      }}
      validationSchema={requestAccessSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <BottomSheetModal
            {...modalProps}
            callToActionButtonText={callToActionButtonText}
            callToActionOnClick={submitForm}
          >
            <Text color="brand-500" size="xs">
              Here to see
            </Text>
            <Row align="baseline" className="mb-6">
              <SpaceBetween className="pl-2">
                <Text size="lg">
                  {isMainOffice && "Main Office"}
                  {!isMainOffice &&
                    safeJoin(". ")(tenant.publicFirstName.charAt(0), tenant.publicLastName)}
                </Text>
                {!isMainOffice &&
                  directoryDisplayFormat === BuildingDirectoryDisplayFormat.NAME_AND_UNIT && (
                    <Text weight="light">{tenant.unitName}</Text>
                  )}
              </SpaceBetween>
            </Row>
            <InputField name="visitorName" label="Visitor Name" ref={visitorNameInputRef} />
          </BottomSheetModal>
        </Form>
      )}
    </EnhancedFormik>
  );
};

interface BuildingDoorDirectoryParams {
  doorUuid: string;
}

const BuildingDoorDirectory: React.FC<RouteComponentProps<BuildingDoorDirectoryParams>> = ({
  history,
  match: {
    params: { doorUuid },
  },
}) => {
  // check to see if this browser has devices to support our video calling
  const { hasDevices } = useDevices();

  const {
    modalProps: accessModalProps,
    modalState: accessModalState,
    setModalStateAndVisible: setAccessModalStateAndVisible,
    setModalVisible: setAccessModalVisible,
  } = useModalState<{
    callToActionButtonText: string;
    tenant: DirectoryProfile;
    onSubmit: (profile: DirectoryProfile, visitorName: string) => void;
  } | null>(null);

  const {
    modalProps: accessStatusModalProps,
    modalState: accessStatusModalState,
    setModalStateAndVisible: setAccessStatusModalStateAndVisible,
  } = useModalState<{
    color: "primary" | "danger";
    message: string;
  } | null>(null);

  const [query, setQuery] = useState("");
  const [requestingAccessProfileId, setRequestingAccessProfileId] = useState<string | null>(null);
  const { data, loading, error } = useEnhancedQuery<DoorDirectory, DoorDirectoryVariables>(
    DOOR_DIRECTORY_QUERY,
    {
      variables: { doorUuid },
    },
    {
      auth: false,
    }
  );

  const [requestVideoCall, { loading: requestingVideoCallLoading }] = useEnhancedMutation<
    RequestVideoChat,
    RequestVideoChatVariables
  >(REQUEST_VIDEO_CHAT, undefined, { auth: false });

  const [requestVisitorAccess, { loading: requestingAccessLoading }] = useEnhancedMutation<
    RequestVisitorAccess,
    RequestVisitorAccessVariables
  >(REQUEST_VISITOR_ACCESS, undefined, { auth: false });

  const awaitingTenantResponse = requestingAccessLoading || requestingVideoCallLoading;

  const buildingName = data?.directory?.building?.name;
  const doorName = data?.directory?.door?.name;

  const handleRequestDoorAccess = useCallback(
    async (profile: DirectoryProfile, visitorName: string) => {
      setRequestingAccessProfileId(profile.id);
      setAccessModalVisible(false);

      try {
        const result = await requestVisitorAccess({
          variables: { doorUuid, profileId: profile.id, visitorName },
        });

        if (result.data?.requestVisitorAccess?.success) {
          setAccessStatusModalStateAndVisible({
            color: "primary",
            message: result.data.requestVisitorAccess.message || "Request sent to the resident.",
          });
          window.analytics.track("Ping From Directory", {
            source: "Web",
            buildingName,
            doorName,
            visitorName,
            residentName: `${profile.publicFirstName} ${profile.publicLastName}`,
          });
        } else {
          throw new Error(result.data?.requestVisitorAccess?.message || "");
        }
      } catch (err) {
        setAccessStatusModalStateAndVisible({
          color: "danger",
          message: err.message || "There was a problem requesting acccess from the resident",
        });
      }

      setRequestingAccessProfileId(null);
    },
    [
      buildingName,
      doorName,
      doorUuid,
      requestVisitorAccess,
      setAccessStatusModalStateAndVisible,
      setAccessModalVisible,
    ]
  );

  const handleRequestVideoCall = useCallback(
    async (profile: DirectoryProfile, visitorName: string) => {
      setRequestingAccessProfileId(profile.id);
      setAccessModalVisible(false);

      try {
        const result = await requestVideoCall({
          variables: { doorUuid, profileId: profile.id, visitorName },
        });

        if (result.data?.requestVideoChat?.success && result.data.requestVideoChat.token) {
          window.analytics.track("Video Call From Directory", {
            source: "Web",
            buildingName,
            doorName,
            visitorName,
            residentName: `${profile.publicFirstName} ${profile.publicLastName}`,
          });
          const { token } = result.data.requestVideoChat;
          history.push(makeUrl(VIDEO_CHAT_URL, { token }, { remoteName: profile.publicFirstName }));
          return;
        }
        throw new Error(result.data?.requestVideoChat?.message || "");
      } catch (err) {
        setAccessStatusModalStateAndVisible({
          color: "danger",
          message: err.message || "There was a problem requesting acccess from the resident",
        });
      }

      setRequestingAccessProfileId(null);
    },
    [
      buildingName,
      doorName,
      history,
      setAccessModalVisible,
      setAccessStatusModalStateAndVisible,
      doorUuid,
      requestVideoCall,
    ]
  );

  const filteredTenants = useMemo(() => {
    const tenants = data?.directory?.directoryProfiles || [];

    if (!query || !query.length) {
      return tenants;
    }

    const terms = query.trim().split(" ");

    if (!terms || !terms.length) {
      return tenants;
    }

    return terms.reduceRight(
      (results, term) => matchSorter(results, term, matchSorterConfig),
      tenants
    );
  }, [data, query]);

  if (loading) {
    return (
      <Page>
        <Loader className="mt-4" />
      </Page>
    );
  }

  if (error || !data?.directory) {
    // TODO: Error page
    return <div>Error: {error?.message}</div>;
  }

  const directoryDisplayFormat = data?.directory?.building?.directoryDisplayFormat;
  const mainOfficePhoneNumber = data?.directory?.door?.mainOfficePhoneNumber;

  return (
    <Page
      className={mainOfficePhoneNumber !== null ? styles.mainOfficeVisibleContainer : ""}
      title="Directory"
      header={
        <div className={styles.header}>
          <h2>{safeJoinWithComma(buildingName, doorName)}</h2>
          <h3>Directory</h3>
        </div>
      }
    >
      <div>
        <SearchField
          value={query}
          onChange={evt => setQuery(evt.target.value)}
          onRequestClear={() => setQuery("")}
          placeholder="Search by name or unit"
        />
      </div>
      <ul>
        {filteredTenants.length > 0 ? (
          filteredTenants.map((tenant, idx) => {
            const formattedTenantName = safeJoin(". ")(
              tenant.publicFirstName.charAt(0),
              tenant.publicLastName
            );

            const isMainOffice = tenant.publicFirstName === "Main Office";

            return (
              <li key={tenant.id} className={styles.tenantLineItem}>
                <span className={styles.tenantInfo}>
                  <Avatar
                    imgAlt={`avatar for ${formattedTenantName}`}
                    initials={safeJoinInitials(
                      tenant.publicFirstName[0],
                      tenant?.publicLastName?.[0]
                    )}
                    profileImageUrl={tenant.profileImage?.url}
                  />
                  <span>
                    <p>
                      {isMainOffice && "Main Office"}
                      {!isMainOffice && formattedTenantName}
                    </p>
                    {!isMainOffice &&
                      directoryDisplayFormat === BuildingDirectoryDisplayFormat.NAME_AND_UNIT && (
                        <p className={styles.tenantUnitName}>{tenant.unitName}</p>
                      )}
                  </span>
                </span>
                <span className={styles.actions}>
                  <IconButton
                    disabled={awaitingTenantResponse}
                    loading={requestingAccessProfileId === tenant.id && requestingAccessLoading}
                    onClick={() =>
                      setAccessModalStateAndVisible({
                        callToActionButtonText: "Ping",
                        tenant,
                        onSubmit: handleRequestDoorAccess,
                      })
                    }
                  >
                    <RequestDoorAccessIcon height={20} width={20} />
                  </IconButton>
                  {hasDevices && (
                    <IconButton
                      disabled={awaitingTenantResponse}
                      loading={
                        requestingAccessProfileId === tenant.id && requestingVideoCallLoading
                      }
                      onClick={() => {
                        setAccessModalStateAndVisible({
                          callToActionButtonText: "Call",
                          tenant,
                          onSubmit: handleRequestVideoCall,
                        });
                      }}
                    >
                      <VideoCallIcon height={20} width={20} />
                    </IconButton>
                  )}
                </span>
              </li>
            );
          })
        ) : (
          <Text align="center" className="py-8">
            No Residents found.
          </Text>
        )}
      </ul>

      <RequestAccessBottomSheet
        modalProps={accessModalProps}
        onSubmit={({ visitorName }) => {
          if (!accessModalState) return;
          accessModalState.onSubmit(accessModalState.tenant, visitorName);
        }}
        callToActionButtonText={accessModalState?.callToActionButtonText || ""}
        tenant={accessModalState?.tenant || null}
        directoryDisplayFormat={directoryDisplayFormat}
      />
      <FullScreenConfirmationModal
        color={accessStatusModalState?.color}
        {...accessStatusModalProps}
        icon={
          accessStatusModalState?.color !== "danger" ? (
            <CircleCheckmark className="text-white fill-current" height={100} width={100} />
          ) : null
        }
        message={accessStatusModalState?.message || ""}
        textColor="white"
      />
      {mainOfficePhoneNumber !== null && (
        <div className={styles.mainOfficeCallButton}>
          <a
            className={styles.mainOfficeCallButtonLink}
            href={`tel:${data?.directory?.door?.mainOfficePhoneNumber}`}
            onClick={() => {
              if (buildingName && doorName) {
                window.analytics.track("Call Main Office From Directory", {
                  source: "Web",
                  buildingName,
                  doorName,
                });
              }
            }}
          >
            <svg
              className={styles.mainOfficeCallButtonIcon}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.61 11.99 12.86 11.89 13.12 11.89C13.22 11.89 13.33 11.9 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0ZM3.54 2C3.6 2.89 3.75 3.76 3.99 4.59L2.79 5.79C2.38 4.59 2.12 3.32 2.03 2H3.54ZM13.4 14.02C14.25 14.26 15.12 14.41 16 14.47V15.96C14.68 15.87 13.41 15.61 12.2 15.21L13.4 14.02Z"
                fill="white"
              />
            </svg>
            Call Main Office
          </a>
        </div>
      )}
    </Page>
  );
};

export default BuildingDoorDirectory;
