import Sentry from "@sugarliving/sentry";
import { useInterval } from "@sugarliving/use-interval";
import cx from "classnames";
import React, { useState } from "react";
import styles from "./VisitorAccess.module.css";
import { UseVisitorAccess, UseVisitorAccessVariables } from "./__generated__/UseVisitorAccess";
import { VisitorAccess_visitorAccess as VisitorAccessDataType } from "./__generated__/VisitorAccess";
import { USE_VISITOR_ACCESS_MUTATION } from "./queries";
import { ReactComponent as BuildingIcon } from "assets/svg/building.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/lock-unlock-alt.svg";
import { IconButton } from "components/common/Button";
import { EnhancedFormik, Form, FormStatus, SubmitButton } from "components/common/Form";
import Page from "components/common/Page";
import { useEnhancedMutation } from "hooks/apollo";
import { safeJoinWithSpace, formatTimeSince } from "uplift-core";

const EmptyInitialValues = {};

type EmptyFormValues = typeof EmptyInitialValues;

interface VisitorAccessUnlockProps {
  visitorToken: string;
  visitorAccessData: VisitorAccessDataType;
  unlockSuccessCallback?: () => void;
}

const VisitorAccessUnlock: React.FC<VisitorAccessUnlockProps> = ({
  visitorToken,
  visitorAccessData,
  unlockSuccessCallback,
}) => {
  const [unlockPanelVisible, setUnlockPanelVisibility] = useState(false);
  const [doorUnlockCountdown, setDoorUnlockCountdown] = useState(0);

  const [visitorAccess] = useEnhancedMutation<UseVisitorAccess, UseVisitorAccessVariables>(
    USE_VISITOR_ACCESS_MUTATION,
    {},
    { auth: false }
  );

  useInterval(
    () => {
      setDoorUnlockCountdown(prevCount => {
        const nextCount = prevCount - 1;
        if (nextCount <= 0) {
          unlockSuccessCallback && unlockSuccessCallback();
        }
        return nextCount;
      });
    },
    doorUnlockCountdown > 0 ? 1000 : null
  );

  const { inviter, buildingName, doorName, expirationTs } = visitorAccessData;

  return (
    <Page fullBg>
      <EnhancedFormik<EmptyFormValues>
        initialValues={EmptyInitialValues}
        onSubmit={async (values, { setFormError, setFormSuccess }) => {
          try {
            const { data } = await visitorAccess({
              variables: {
                visitorToken,
              },
            });
            if (data?.useVisitorAccess?.success) {
              setDoorUnlockCountdown(3);
              setFormSuccess("Success! the Door was unlocked…");
            } else {
              const err = new Error(
                data?.useVisitorAccess?.message || "Unable to unlock the door."
              );
              err.name = "AppError";
              throw err;
            }
          } catch (err) {
            setUnlockPanelVisibility(false);
            if (err.name === "AppError") {
              setFormError(err);
            } else {
              setFormError("Hmm, that didn’t work. Are you online? If so, contact support.");
            }
            Sentry.captureException(err);
          }
        }}
      >
        {({ status, isSubmitting, setFormError }) => (
          <div className={styles.contentContainer}>
            <div>
              {status.formSuccess && (
                <div className="flex items-center justify-between">
                  <div className="inline-block">
                    <div className={cx(styles.infoText, styles.infoTextHero)}>You&apos;re in!</div>
                    <div className={cx(styles.infoText, "mt-2")}>This key will expire in</div>
                  </div>
                  <span className={styles.countDownText}>{doorUnlockCountdown}</span>
                </div>
              )}
              {isSubmitting && (
                <>
                  <div className={cx(styles.infoText, styles.infoTextHero)}>Opening the door…</div>
                  <div className={styles.infoText}>
                    Hang tight while we unlock the door for you.
                  </div>
                </>
              )}
              {!status.formSuccess && !isSubmitting && (
                <>
                  <div className={styles.infoText}>
                    <strong>
                      {safeJoinWithSpace(inviter.preferredFirstName, inviter.preferredLastName)}
                    </strong>{" "}
                    has provided you one-time access to the <strong>{doorName}</strong> at{" "}
                    <strong>
                      <u>{buildingName}</u>
                    </strong>
                    .
                  </div>
                  <div className={styles.infoText}>
                    This key expires in {formatTimeSince(expirationTs)}.
                  </div>
                  <div className={styles.infoText}>
                    Use the button below to unlock the door when you arrive.
                  </div>
                </>
              )}
            </div>
            <Form noValidate className={styles.unlockForm}>
              {status.formError && <FormStatus status={status} className="shadow-md" />}

              {!unlockPanelVisible ? (
                <IconButton
                  title="View doors"
                  className={styles.showUnlockPanelButton}
                  onClick={() => {
                    setUnlockPanelVisibility(true);
                    setFormError(null);
                  }}
                >
                  <UnlockIcon className={cx(styles.icon, styles.showUnlockPanelButtonIcon)} />
                </IconButton>
              ) : (
                <div className={styles.unlockPanel}>
                  <div className={styles.unlockPanelNub} />
                  <div className={styles.unlockPanelDoor}>
                    <span className={styles.unlockPanelDoorItem}>
                      <BuildingIcon className={cx(styles.icon, styles.unlockPanelDoorIcon)} />
                      <span className={styles.unlockPanelDoorName}>{doorName}</span>
                    </span>
                    <SubmitButton
                      className={cx(
                        styles.unlockPanelDoorButton,
                        status.formSuccess && styles.unlockPanelDoorButtonSuccess
                      )}
                      disabled={Boolean(status.formSuccess)}
                    >
                      <UnlockIcon className={styles.icon} />
                      <span className={styles.unlockPanelDoorButtonText}>
                        {isSubmitting && "Unlocking…"}
                        {status.formSuccess && "Unlocked"}
                        {!isSubmitting && !status.formSuccess && "Unlock"}
                      </span>
                    </SubmitButton>
                  </div>
                </div>
              )}
            </Form>
          </div>
        )}
      </EnhancedFormik>
    </Page>
  );
};

export default VisitorAccessUnlock;
