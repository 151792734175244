import Sentry from "@sugarliving/sentry";
import gql from "graphql-tag";
import React from "react";
import * as yup from "yup";
import styles from "./Auth.module.css";
import { LoginUser, LoginUserVariables } from "./__generated__/LoginUser";
import { EnhancedFormik, Form, FormStatus, InputField, SubmitButton } from "components/common/Form";
import { HOME_URL } from "constants/urls";
import { useEnhancedMutation } from "hooks/apollo";

const LOGIN_USER_QUERY = gql`
  mutation LoginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      success
      message
    }
  }
`;

interface FormValues {
  username: string;
  password: string;
}

const validationSchema = yup.object().shape({
  username: yup.string().required().label("Username"),
  password: yup.string().required().label("Password"),
});

const Login: React.FC = () => {
  const [loginUser] = useEnhancedMutation<LoginUser, LoginUserVariables>(
    LOGIN_USER_QUERY,
    {},
    { auth: false }
  );

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Login</h1>
      <EnhancedFormik<FormValues>
        initialValues={{ username: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFormError, setFormSuccess }) => {
          try {
            const { data } = await loginUser({ variables: values });
            if (data?.loginUser?.success) {
              setFormSuccess("Success! Redirecting you...");
              window.location.assign(HOME_URL);
            } else {
              setFormError(data?.loginUser?.message);
            }
          } catch (err) {
            Sentry.captureException(err);
            setFormError(err);
          }
        }}
      >
        {({ status }) => (
          <Form noValidate>
            <InputField name="username" label="Username" placeholder="Username" required />
            <InputField
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              required
            />
            <FormStatus status={status} />
            <SubmitButton className="mt-6">Login</SubmitButton>
          </Form>
        )}
      </EnhancedFormik>
    </div>
  );
};

export default Login;
