import cx from "classnames";
import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./VisitorAccess.module.css";
import VisitorAccessRequest from "./VisitorAccessRequest";
import VisitorAccessUnlock from "./VisitorAccessUnlock";
import {
  VisitorAccess as VisitorAccessType,
  VisitorAccessVariables,
} from "./__generated__/VisitorAccess";
import { VISITOR_ACCESS_QUERY } from "./queries";
import Page from "components/common/Page";
import { useEnhancedQuery } from "hooks/apollo";

interface VisitorAccessParams {
  visitorToken: string;
}

const VisitorAccess: React.FC<RouteComponentProps<VisitorAccessParams>> = ({
  match: {
    params: { visitorToken },
  },
}) => {
  const {
    data: visitorAccessData,
    loading: visitorAccessLoading,
    error: visitorAccessError,
    refetch: visitorAccessRefetch,
  } = useEnhancedQuery<VisitorAccessType, VisitorAccessVariables>(
    VISITOR_ACCESS_QUERY,
    {
      variables: { token: visitorToken },
    },
    { auth: false }
  );

  const unlockSuccessCallback = useCallback(() => visitorAccessRefetch({ token: visitorToken }), [
    visitorAccessRefetch,
    visitorToken,
  ]);

  // Loading
  if (visitorAccessLoading) {
    return (
      <Page fullBg>
        <div className={styles.infoText}>Loading…</div>
      </Page>
    );
  }

  // Graphql error
  if (visitorAccessError) {
    return (
      <Page fullBg color="danger">
        <div className={cx(styles.infoText, styles.infoTextHero)}>Error</div>
        <div className={styles.infoText}>
          {visitorAccessError.message || "Please refresh the page or ask for another key."}
        </div>
      </Page>
    );
  }

  // Invalid key if data is null
  if (!visitorAccessData?.visitorAccess) {
    return (
      <Page fullBg color="danger">
        <div className={cx(styles.infoText, styles.infoTextHero)}>Invalid key</div>
        <div className={styles.infoText}>Please ask for another key</div>
      </Page>
    );
  }

  // If expired, render the access request form
  if (visitorAccessData.visitorAccess.isExpired) {
    return <VisitorAccessRequest visitorAccessData={visitorAccessData.visitorAccess} />;
  }

  // Everything checks out, render unlock view
  return (
    <VisitorAccessUnlock
      visitorAccessData={visitorAccessData.visitorAccess}
      visitorToken={visitorToken}
      unlockSuccessCallback={unlockSuccessCallback}
    />
  );
};

export default VisitorAccess;
