export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const INTERCOM_URL = "/inapp/intercom";
export const VISITOR_ACCESS_URL = "/visitor-access/:visitorToken";
export const BUILDING_DOOR_DIRECTORY_URL = "/door-directory/:doorUuid";
export const VIDEO_CHAT_URL = "/visitor-access/chat/:token";

export const MOBILE_VISTOR_ACCESS_REQUEST_URL = "/visitor-access/request";
export const MOBILE_TENANT_VIDEO_CHAT_URL = "/visitor-access/tenant-video-chat";
