import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useUserContext, safeJoinWithSpace } from "uplift-core";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      window.analytics.page(location.pathname);
    }
  }, [location]);
}

const App: React.FC = ({ children }) => {
  const { loading, currentUser } = useUserContext();
  const { update } = useIntercom();

  useEffect(() => {
    if (!loading && currentUser) {
      update({
        userId: currentUser.id,
        email: currentUser.email,
        phone: currentUser.phoneNumber || undefined,
        name: safeJoinWithSpace(
          currentUser.profile.preferredFirstName,
          currentUser.profile.preferredLastName
        ),
      });
    }
  }, [loading, currentUser, update]);

  usePageViews();

  return <div>{children}</div>;
};

export default App;
