import { FormikStatus } from "@sugarliving/formik";
import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";
import Alert from "components/common/Alert";

interface FormStatusProps {
  className?: string;
  status: FormikStatus;
}

const FormStatus: React.FC<FormStatusProps> = ({ className, status }) => {
  if (status) {
    if (status.formSuccess) {
      return (
        <Alert className={cx(styles.formStatus, className)} theme="success">
          {status.formSuccess}
        </Alert>
      );
    }
    if (status.formError) {
      return (
        <Alert className={cx(styles.formStatus, className)} theme="error">
          {status.formError instanceof Error ? status.formError.message : status.formError}
        </Alert>
      );
    }
  }
  return null;
};

export { FormStatus };
