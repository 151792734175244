import gql from "graphql-tag";

export const DOOR_DIRECTORY_QUERY = gql`
  query DoorDirectory($doorUuid: String!) {
    directory(doorUuid: $doorUuid) {
      building {
        id
        name
        address
        address2
        directoryDisplayFormat
      }
      door {
        id
        name
        mainOfficePhoneNumber
      }
      directoryProfiles {
        id
        publicFirstName
        publicLastName
        unitName
        profileImage {
          id
          url
        }
      }
    }
  }
`;

export const REQUEST_VIDEO_CHAT = gql`
  mutation RequestVideoChat(
    $doorUuid: String!
    $profileId: ProfileSmartID!
    $visitorName: String!
  ) {
    requestVideoChat(doorUuid: $doorUuid, profileId: $profileId, visitorName: $visitorName) {
      success
      message
      token
    }
  }
`;

export const REQUEST_VISITOR_ACCESS = gql`
  mutation RequestVisitorAccess(
    $doorUuid: String!
    $profileId: ProfileSmartID!
    $visitorName: String!
  ) {
    requestVisitorAccess(doorUuid: $doorUuid, profileId: $profileId, visitorName: $visitorName) {
      success
      message
    }
  }
`;
