import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { HOME_URL } from "constants/urls";

const MobileRoute: React.FC<RouteComponentProps> = ({ match, location }) => {
  window.location.assign(
    `sugar-living://${location.pathname.replace(/^\//, "").replace(/\/$/, "")}/${location.search}`
  );
  return (
    <p>
      Redirecting you to the app...{" "}
      <Link className="text-blue-500" to={HOME_URL}>
        Home
      </Link>
    </p>
  );
};

export default MobileRoute;
