import cx from "classnames";
import React from "react";
import ReactModal, { Props as BaseModalProps } from "react-modal";
import styles from "./Modal.module.css";
import Button from "components/common/Button";
import SpaceBetween from "components/common/SpaceBetween";
import Text from "components/common/Text";

export type OurModalProps = BaseModalProps & { className?: string };

export const OurModal: React.FC<OurModalProps> = ({
  className,
  closeTimeoutMS = 400,
  ...props
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={closeTimeoutMS}
      className={cx(styles.modal, className)}
      overlayClassName={styles.modalOverlay}
      {...props}
    />
  );
};

export const ActionButton = ({
  className,
  children,
  onClick,
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button className={cx(styles.actionButton, className)} onClick={onClick}>
      {children}
    </Button>
  );
};

export type ModalType = "default" | "success" | "error";

export interface ModalLayoutProps {
  onRequestClose: () => void;
  onAfterClose?: () => void;
  closeButtonText?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
  type?: ModalType;
  actions?: React.ReactNode[];
  children?: React.ReactNode;
}

export const ModalLayout: React.FC<ModalLayoutProps> = ({
  onRequestClose,
  closeButtonText = "Close",
  title = "Oops!",
  message,
  type = "default",
  actions,
  children,
}) => {
  const headerTextColor = type === "error" ? "white" : "black";

  return (
    <div className={styles.modalRoot}>
      <div
        className={cx(styles.modalHeader, {
          [styles.modalDefault]: type === "default",
          [styles.modalSuccess]: type === "success",
          [styles.modalError]: type === "error",
        })}
      >
        <SpaceBetween className="mt-2">
          <Text as="h1" size="2xl" weight="medium" color={headerTextColor}>
            {title}
          </Text>
          {message && <Text color={headerTextColor}>{message}</Text>}
        </SpaceBetween>
      </div>

      {children && <div className={styles.modalContent}>{children}</div>}
      <div className={styles.modalActions}>
        {actions}
        <ActionButton className={styles.modalCloseButton} onClick={onRequestClose}>
          {closeButtonText}
        </ActionButton>
      </div>
    </div>
  );
};

export interface ModalProps extends ModalLayoutProps {
  isOpen: boolean;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  onAfterClose,
  closeButtonText,
  title,
  message,
  type,
  children,
  actions,
  className,
}) => (
  <OurModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    onAfterClose={onAfterClose}
    className={className}
  >
    <ModalLayout
      title={title}
      message={message}
      onRequestClose={onRequestClose}
      closeButtonText={closeButtonText}
      type={type}
      actions={actions}
    >
      {children}
    </ModalLayout>
  </OurModal>
);
