import { useEffect, useMemo, useState } from "react";
import Sentry from "helpers/sentry";

const isKind = (kind: MediaDeviceInfo["kind"]) => (device: MediaDeviceInfo) => device.kind === kind;

export const useDevices = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const catchError = (err: Error) => {
      Sentry.captureException(err);
      setError(new Error("Video calling is not supported on this device."));
    };
    const getDevices = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(deviceList => setDevices(deviceList))
        .catch(catchError);
    };

    try {
      navigator.mediaDevices.addEventListener("devicechange", getDevices);
      getDevices();

      return () => {
        navigator.mediaDevices.removeEventListener("devicechange", getDevices);
      };
    } catch (err) {
      catchError(err);
      return () => {
        /* noop */
      };
    }
  }, []);

  return useMemo(() => {
    const audioInputDevices = devices.filter(isKind("audioinput"));
    const audioOutputDevices = devices.filter(isKind("audiooutput"));
    const videoInputDevices = devices.filter(isKind("videoinput"));
    const hasAudioInputDevices = audioInputDevices.length > 0;
    const hasVideoInputDevices = videoInputDevices.length > 0;

    return {
      error,
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
      hasDevices: hasAudioInputDevices && hasVideoInputDevices,
    };
  }, [devices, error]);
};
