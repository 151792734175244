import gql from "graphql-tag";

export const USE_VISITOR_ACCESS_MUTATION = gql`
  mutation UseVisitorAccess($visitorToken: String!) {
    useVisitorAccess(visitorToken: $visitorToken) {
      success
      message
    }
  }
`;

export const VISITOR_ACCESS_QUERY = gql`
  query VisitorAccess($token: String!) {
    visitorAccess(token: $token) {
      doorName
      doorUuid
      inviter {
        id
        preferredFirstName
        preferredLastName
      }
      buildingName
      expirationTs
      isExpired
    }
  }
`;
