import Sentry from "@sugarliving/sentry";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import styles from "./Auth.module.css";
import { HOME_URL } from "constants/urls";
import { useEnhancedMutation } from "hooks/apollo";

const LOGOUT_USER = gql`
  mutation LogoutUser {
    logoutUser {
      success
    }
  }
`;

const Logout: React.FC = () => {
  const [logoutUser, { loading, error }] = useEnhancedMutation(LOGOUT_USER);

  useEffect(() => {
    const logout = async () => {
      try {
        await logoutUser();
      } catch (err) {
        Sentry.captureMessage(err);
      } finally {
        window.location.assign(HOME_URL);
      }
    };
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{loading ? "Logging you out..." : "Success! Redirecting..."}</h1>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Logout;
