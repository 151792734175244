import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useOnHideIntercom } from "helpers/intercom";
import { postWebViewMessage } from "helpers/webview";
import { WEBVIEW_EVENTS } from "uplift-core";

interface IntercomProps extends RouteComponentProps {
  className?: string;
}

const Intercom: React.FC<IntercomProps> = () => {
  const { show, hide } = useIntercom();
  useOnHideIntercom(() => {
    show();
    postWebViewMessage({ event: WEBVIEW_EVENTS.INTERCOM_ON_HIDE });
  });
  useEffect(() => {
    show();
    return () => {
      hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Intercom;
