import React, { useContext, useMemo } from "react";
import {
  useLocalTracks,
  LocalTracks,
  LocalAudioTrack,
  LocalVideoTrack,
  LocalTrackStatus,
} from "./hooks/useLocalTracks";

export interface VideoCallContextShape {
  localTracks: LocalTracks;
  audioTrack: LocalAudioTrack | undefined;
  videoTrack: LocalVideoTrack | undefined;
  isAcquiringLocalTracks: boolean;
  localTracksAcquired: boolean;
  localTracksStatus: LocalTrackStatus;
  getAudioAndVideoTracks: () => void;
  stopAudioAndVideoTracks: () => void;
}

export { LocalTrackStatus };

const noop = () => {
  /* noop */
};

const VideoCallContext = React.createContext<VideoCallContextShape>({
  localTracks: [],
  audioTrack: undefined,
  videoTrack: undefined,
  isAcquiringLocalTracks: false,
  localTracksAcquired: false,
  localTracksStatus: LocalTrackStatus.IDLE,
  getAudioAndVideoTracks: noop,
  stopAudioAndVideoTracks: noop,
});

export const useVideoCallContext = () => useContext(VideoCallContext);

export const VideoCallProvider: React.FC = ({ children }) => {
  const {
    localTracks,
    audioTrack,
    videoTrack,
    isAcquiringLocalTracks,
    localTracksAcquired,
    status: localTracksStatus,
    getAudioAndVideoTracks,
    stopAudioAndVideoTracks,
  } = useLocalTracks();

  const contextValue = useMemo(
    () => ({
      localTracks,
      audioTrack,
      videoTrack,
      isAcquiringLocalTracks,
      localTracksAcquired,
      localTracksStatus,
      getAudioAndVideoTracks,
      stopAudioAndVideoTracks,
    }),
    [
      localTracks,
      audioTrack,
      videoTrack,
      isAcquiringLocalTracks,
      localTracksStatus,
      localTracksAcquired,
      getAudioAndVideoTracks,
      stopAudioAndVideoTracks,
    ]
  );

  return <VideoCallContext.Provider value={contextValue}>{children}</VideoCallContext.Provider>;
};
