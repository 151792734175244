/* eslint-disable react/button-has-type */

import cx from "classnames";
import React from "react";
import styles from "./Button.module.css";
import Loader from "components/common/Loader";

type ReactButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface ButtonProps extends ReactButtonProps {
  className?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  type = "button",
  disabled,
  loading,
  ...buttonProps
}) => {
  return (
    <button
      className={cx(styles.root, { [styles.loading]: loading }, className)}
      type={type}
      disabled={disabled || loading}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export const UnstyledButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <button type="button" className={styles.unstyledButton} {...props}>
    {children}
  </button>
);

export const IconButton = ({
  className,
  children,
  type = "button",
  disabled,
  loading,
  ...buttonProps
}: ButtonProps) => {
  return (
    <button
      className={cx(styles.iconButtonRoot, {}, className)}
      type={type}
      disabled={disabled || loading}
      {...buttonProps}
    >
      {loading ? (
        <Loader className={styles.iconButtonLoading} size="small" inline message={null} />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
