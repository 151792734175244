/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum BuildingDirectoryDisplayFormat {
  NAME_AND_UNIT = "NAME_AND_UNIT",
  NAME_ONLY = "NAME_ONLY",
}

/**
 * An enumeration.
 */
export enum BuildingPropertyManagementSoftware {
  APP_FOLIO = "APP_FOLIO",
  CLICK_PAY = "CLICK_PAY",
  MRI = "MRI",
  NONE = "NONE",
  RENT_CAFE = "RENT_CAFE",
  YARDI = "YARDI",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
