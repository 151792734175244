import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";
import { FieldError } from "components/common/Form/FieldError";
import { Label } from "components/common/Form/Label";

interface FieldContainerProps {
  id: string;
  className?: string;
  error?: string;
  label: string;
  labelClassName?: string;
  showError?: boolean;
  required?: boolean;
}

export const FieldContainer: React.FC<FieldContainerProps> = ({
  error,
  showError,
  className,
  label,
  labelClassName,
  id,
  required,
  children,
}) => {
  return (
    <>
      <div
        className={cx(
          styles.fieldContainer,
          showError && error && styles.fieldContainerError,
          className
        )}
      >
        <Label htmlFor={id}>
          <span className={styles.label}>{label}</span>
          {required && (
            <span className={styles.required} title="Required Field">
              *
            </span>
          )}
          <div className={cx(styles.inputWrapper)}>{children}</div>
        </Label>
      </div>
      {showError && <FieldError>{error}</FieldError>}
    </>
  );
};
