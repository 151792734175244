import cx from "classnames";
import React from "react";
import { OurModal, ModalProps } from "./Modal";
import styles from "./Modal.module.css";
import { ReactComponent as BackArrowIcon } from "assets/svg/back-arrow.svg";
import { UnstyledButton } from "components/common/Button";
import SpaceBetween from "components/common/SpaceBetween";
import Text, { TextProps } from "components/common/Text";

export interface FullScreenModalProps extends Omit<ModalProps, "title"> {
  color?: "success" | "primary" | "danger";
  translucent?: boolean;
  children: React.ReactNode;
}

export const FullScreenModal = ({
  isOpen,
  onRequestClose,
  onAfterClose,
  children,
  translucent,
  color = "primary",
}: FullScreenModalProps) => {
  return (
    <OurModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      className={styles.fullScreenModal}
    >
      <div
        className={cx(styles.fullScreenModalRoot, {
          [styles.fullScreenModalPrimary]: color === "primary",
          [styles.fullScreenModalSuccess]: color === "success",
          [styles.fullScreenModalDanger]: color === "danger",
          [styles.fullScreenModalTranslucent]: translucent,
        })}
      >
        <div className={styles.fullScreenModalHeader}>
          <UnstyledButton className="text-medium text-white" onClick={onRequestClose}>
            <BackArrowIcon height="30" width="30" />
          </UnstyledButton>
        </div>
        <div className={styles.fullScreenModalContent}>{children}</div>
      </div>
    </OurModal>
  );
};

export interface FullScreenConfirmationModalProps extends Omit<FullScreenModalProps, "children"> {
  message: string;
  icon?: React.ReactNode;
  textColor?: TextProps["color"];
}

export const FullScreenConfirmationModal = ({
  message,
  icon,
  textColor = "white",
  ...modalProps
}: FullScreenConfirmationModalProps) => {
  return (
    <FullScreenModal {...modalProps}>
      <SpaceBetween className="mt-6">
        {icon}
        <Text align="center" color={textColor} size="lg">
          {message}
        </Text>
      </SpaceBetween>
    </FullScreenModal>
  );
};
