import Sentry from "@sugarliving/sentry";
import { useCallback, useMemo, useState } from "react";
import { useUID } from "react-uid";
import Video, { LocalVideoTrack, LocalAudioTrack } from "twilio-video";
import { useDevices } from "./useDevices";

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

type LocalTracks = (LocalAudioTrack | LocalVideoTrack)[];
export type { LocalTracks, LocalAudioTrack, LocalVideoTrack };

export enum LocalTrackStatus {
  IDLE = "idle",
  ACQUIRING = "acquiring",
  ACQUIRED = "acquired",
  ERROR = "error",
}

export interface UseLocalTracksResult {
  localTracks: LocalTracks;
  audioTrack: LocalAudioTrack | undefined;
  videoTrack: LocalVideoTrack | undefined;
  isAcquiringLocalTracks: boolean;
  localTracksAcquired: boolean;
  status: LocalTrackStatus;
  getAudioAndVideoTracks: () => Promise<void>;
  stopAudioAndVideoTracks: () => void;
}

export const useLocalTracks = (): UseLocalTracksResult => {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const [status, setStatus] = useState(LocalTrackStatus.IDLE);
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

  const cameraUid = useUID();

  const stopAudioAndVideoTracks = useCallback(() => {
    if (status !== LocalTrackStatus.ACQUIRING && status !== LocalTrackStatus.ACQUIRED) return;

    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }

    setStatus(LocalTrackStatus.IDLE);
  }, [audioTrack, videoTrack, status]);

  const getAudioAndVideoTracks = useCallback(() => {
    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
    if (
      status === LocalTrackStatus.ACQUIRING ||
      status === LocalTrackStatus.ACQUIRED ||
      audioTrack ||
      videoTrack
    )
      return Promise.resolve();

    setStatus(LocalTrackStatus.ACQUIRING);

    const localTrackConstraints = {
      video: hasVideoInputDevices && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, number>),
        name: cameraUid,
      },
      audio: hasAudioInputDevices,
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then(tracks => {
        const localVideoTrack = tracks.find(track => track.kind === "video");
        const localAudioTrack = tracks.find(track => track.kind === "audio");

        if (localVideoTrack) {
          setVideoTrack(localVideoTrack as LocalVideoTrack);
        }
        if (localAudioTrack) {
          setAudioTrack(localAudioTrack as LocalAudioTrack);
        }

        setStatus(LocalTrackStatus.ACQUIRED);
      })
      .catch(err => {
        Sentry.captureException(err);
        setStatus(LocalTrackStatus.ERROR);
      });
  }, [cameraUid, hasAudioInputDevices, hasVideoInputDevices, audioTrack, videoTrack, status]);

  return useMemo(
    () => ({
      localTracks: [audioTrack, videoTrack].filter(track => track !== undefined) as LocalTracks,
      audioTrack,
      videoTrack,
      isAcquiringLocalTracks: status === LocalTrackStatus.ACQUIRING,
      localTracksAcquired: status === LocalTrackStatus.ACQUIRED,
      status,
      getAudioAndVideoTracks,
      stopAudioAndVideoTracks,
    }),
    [getAudioAndVideoTracks, stopAudioAndVideoTracks, status, audioTrack, videoTrack]
  );
};
