import cx from "classnames";
import React, { useMemo } from "react";

import SpaceBetween from "../SpaceBetween";

import styles from "./Loader.module.css";

const numberOfLinesForSize = {
  small: 3,
  medium: 5,
};

export interface LoaderProps {
  className?: string;
  lineClassName?: string;
  inline?: boolean;
  message?: string | null;
  size?: "small" | "medium";
}

const Loader = ({
  className,
  lineClassName,
  size = "medium",
  inline,
  message = "Loading…",
}: LoaderProps) => {
  const lines = useMemo(() => {
    const lineStyle = cx(
      styles.line,
      {
        [styles.lineSmall]: size === "small",
      },
      lineClassName
    );

    return Array.from({ length: numberOfLinesForSize[size] }, (_, idx) => (
      <div key={idx} className={lineStyle} />
    ));
  }, [lineClassName, size]);

  return (
    <div className={cx(styles.root, inline && styles.rootInline, className)}>
      <SpaceBetween className={inline ? "ml-2" : "mt-4"}>
        <div className={styles.loaderContainer}>{lines}</div>
        {message && (
          <div
            className={cx(styles.message, {
              [styles.messageSmall]: size === "small",
              [styles.messageInline]: inline,
            })}
          >
            {message}
          </div>
        )}
      </SpaceBetween>
    </div>
  );
};

export default Loader;
