import cx from "classnames";
import React from "react";
import logoRed from "../../assets/logo-red.svg";
import logo from "../../assets/logo.svg";
import styles from "./Logo.module.css";

export type LogoColors = "primary" | "danger";

interface LogoProps {
  className?: string;
  color?: LogoColors;
}

const LogoAssets: Record<LogoColors, string> = {
  primary: logo,
  danger: logoRed,
};

const Logo = ({ className, color = "primary" }: LogoProps) => {
  return <img className={cx(styles.root, className)} src={LogoAssets[color]} alt="Sugar Living" />;
};

export default Logo;
