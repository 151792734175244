export enum WEBVIEW_EVENTS {
  INTERCOM_ON_HIDE = "INTERCOM_ON_HIDE",
  VIDEO_CALL_ENDED = "VIDEO_CALL_ENDED",
}

export interface WebViewPostMessageData {
  event: WEBVIEW_EVENTS;
}

export const WEBVIEW_CONTEXT = "SUGAR_APP_WEBVIEW_CONTEXT";

export interface WebViewContext extends Record<string, unknown> {
  mobileWebView: true;
}

declare global {
  interface Window {
    [WEBVIEW_CONTEXT]: string;
  }
}

export const setWebviewContext = <Data extends Record<string, unknown>>(data?: Data) => {
  // serializing to JSON will be faster to load on the frontend and should avoid
  // most syntax issues while embedding
  return `window["${WEBVIEW_CONTEXT}"] = '${JSON.stringify({
    mobileWebView: true,
    ...(data || {}),
  })}';`;
};

export const useWebviewContext = () => {
  try {
    return JSON.parse(window[WEBVIEW_CONTEXT]) as WebViewContext;
  } catch (err) {
    return null;
  }
};
