import { ApolloProvider } from "@sugarliving/apollo";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import App from "components/App";
import Login from "components/Auth/Login";
import Logout from "components/Auth/Logout";
import BuildingDoorDirectory from "components/Directory/BuildingDoorDirectory";
import Home from "components/Home";
import Intercom from "components/Intercom";
import MobileRoute from "components/MobileRoute";
import VideoChat from "components/VideoChat";
import VisitorAccess from "components/VisitorAccess";
import { INTERCOM_APP_ID } from "constants/env";
import {
  BUILDING_DOOR_DIRECTORY_URL,
  INTERCOM_URL,
  LOGIN_URL,
  LOGOUT_URL,
  MOBILE_VISTOR_ACCESS_REQUEST_URL,
  MOBILE_TENANT_VIDEO_CHAT_URL,
  VISITOR_ACCESS_URL,
  VIDEO_CHAT_URL,
} from "constants/urls";
import { configureClient } from "helpers/apollo";
import { onHideIntercom, onShowIntercom } from "helpers/intercom";
import { UserContextProvider } from "uplift-core";

const client = configureClient();

const Root: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          autoBootProps={{
            hideDefaultLauncher: true,
          }}
          shouldInitialize
          onHide={onHideIntercom}
          onShow={onShowIntercom}
        >
          <Router>
            <App>
              <Switch>
                <Route exact path={MOBILE_VISTOR_ACCESS_REQUEST_URL} component={MobileRoute} />
                <Route exact path={MOBILE_TENANT_VIDEO_CHAT_URL} component={MobileRoute} />
                <Route exact path={INTERCOM_URL} component={Intercom} />
                <Route exact path={LOGIN_URL} component={Login} />
                <Route exact path={LOGOUT_URL} component={Logout} />
                <Route exact path={VISITOR_ACCESS_URL} component={VisitorAccess} />
                <Route exact path={BUILDING_DOOR_DIRECTORY_URL} component={BuildingDoorDirectory} />
                <Route exact path={VIDEO_CHAT_URL} component={VideoChat} />
                <Route component={Home} />
              </Switch>
            </App>
          </Router>
        </IntercomProvider>
      </UserContextProvider>
    </ApolloProvider>
  );
};

export default Root;
