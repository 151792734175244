import cx from "classnames";
import React, { forwardRef } from "react";
import { useUID } from "react-uid";
import styles from "./Form.module.css";
import { FieldContainer } from "components/common/Form/FieldContainer";
import { useField } from "components/common/Form/Formik";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...otherProps }, ref) => (
    <input ref={ref} className={cx(styles.input, className)} {...otherProps} />
  )
);

interface InputFieldProps extends InputProps {
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  hidden?: boolean;
  name: string;
  label: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      className,
      containerClassName,
      inputClassName,
      labelClassName,
      hidden,
      name,
      label,
      required,
      ...inputProps
    },
    ref
  ) => {
    const [field, meta] = useField<InputProps["value"]>(name);
    const uid = useUID();

    return (
      <FieldContainer
        id={uid}
        showError={Boolean(meta.touched && meta.error)}
        error={meta.error}
        label={label}
        required={required}
        className={cx(containerClassName, hidden && "hidden")}
      >
        <Input
          id={uid}
          ref={ref}
          className={inputClassName}
          required={required}
          {...field}
          {...inputProps}
        />
      </FieldContainer>
    );
  }
);
