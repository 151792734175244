import cx from "classnames";
import React from "react";
import styles from "./Alert.module.css";

interface AlertProps {
  className?: string;
  theme: "success" | "error" | "info";
}

const Alert: React.FC<AlertProps> = ({ children, className, theme }) => {
  return <div className={cx(styles.root, styles[theme], className)}>{children}</div>;
};

export default Alert;
