import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";

interface FieldErrorProps {
  className?: string;
}

export const FieldError: React.FC<FieldErrorProps> = ({ children, className }) => {
  return <div className={cx(styles.fieldError, className)}>{children}</div>;
};
