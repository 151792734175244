import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";

type ReactLabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

export const Label: React.FC<ReactLabelProps> = ({ children, className, htmlFor }) => {
  return (
    <label className={cx(styles.label, className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
