import cx from "classnames";
import React from "react";

const alignments = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
  justify: "text-justify",
};

type Alignment = keyof typeof alignments;

const textColors = {
  black: "text-black",
  white: "text-white",
  "brand-100": "text-brand-100",
  "brand-500": "text-brand-500",
  "gray-100": "text-gray-100",
  "gray-200": "text-gray-200",
  "gray-300": "text-gray-300",
  "gray-400": "text-gray-400",
  "gray-500": "text-gray-500",
  "gray-600": "text-gray-600",
  "gray-700": "text-gray-700",
  "gray-800": "text-gray-800",
  "gray-900": "text-gray-900",
};

type TextColor = keyof typeof textColors;

const fontSizes = {
  xs: "text-xs",
  sm: "text-sm",
  base: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  "2xl": "text-2xl",
  "3xl": "text-3xl",
  "4xl": "text-4xl",
  "5xl": "text-5xl",
  "6xl": "text-6xl",
};

type FontSize = keyof typeof fontSizes;

const fontWeights = {
  hairline: "font-hairline",
  thin: "font-thin",
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
  black: "font-black",
};

type FontWeight = keyof typeof fontWeights;

const lineHeights = {
  none: "leading-none",
  tight: "text-tight",
  snug: "text-snug",
  normal: "text-normal",
};

type LineHeight = keyof typeof lineHeights;

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  as?: string;
  className?: string;
  color?: TextColor;
  align?: Alignment;
  size?: FontSize;
  lineHeight?: LineHeight;
  weight?: FontWeight;
}

const getAlignment = (align: Alignment) => alignments[align];
const getFontSize = (size: FontSize) => fontSizes[size];
const getFontWeight = (weight: FontWeight) => fontWeights[weight];
const getLineHeight = (leading: LineHeight) => lineHeights[leading];
const getTextColor = (color: TextColor) => textColors[color];

const Text = ({
  as: asComponent = "p",
  className,
  children,
  align = "left",
  color = "black",
  lineHeight = "normal",
  size = "base",
  weight = "normal",
}: TextProps) => {
  return React.createElement(
    asComponent,
    {
      className: cx(
        getAlignment(align),
        getFontSize(size),
        getFontWeight(weight),
        getLineHeight(lineHeight),
        getTextColor(color),
        className
      ),
    },
    children
  );
};

export default Text;
