import { ApolloError } from "@sugarliving/apollo";
import Sentry from "@sugarliving/sentry";
import gql from "graphql-tag";
import React, { useContext, useEffect } from "react";
import useCurrentUser, { CurrentUser } from "../../hooks/useCurrentUser";
import { Authenticated } from "./__generated__/Authenticated";

import { useEnhancedQuery } from "hooks/apollo";

interface UserContextShape {
  loading: boolean;
  error?: ApolloError;
  isAuthenticated: boolean;
  currentUser: CurrentUser["currentUser"];
}

export const UserContext = React.createContext<UserContextShape>({
  loading: true,
  isAuthenticated: false,
  currentUser: null,
});

export const useUserContext = () => useContext(UserContext);

export const useAssertUserContext = () => {
  const { currentUser } = useUserContext();

  if (!currentUser) {
    throw new Error("You must be logged in.");
  }

  return currentUser;
};

const AUTHENTICATED_QUERY = gql`
  query Authenticated {
    isAuthenticated
  }
`;

export const UserContextProvider: React.FC = ({ children }) => {
  const {
    loading: authenticatedLoading,
    error: authenticatedError,
    data: authenticatedData,
  } = useEnhancedQuery<Authenticated>(AUTHENTICATED_QUERY, {}, { auth: false });

  const {
    loading: currentUserLoading,
    error: currentUserError,
    data: currentUserData,
  } = useCurrentUser();

  const loading = authenticatedLoading || currentUserLoading;
  const error = authenticatedError || currentUserError;

  const isAuthenticated = authenticatedData ? authenticatedData.isAuthenticated : false;
  const currentUser = currentUserData ? currentUserData.currentUser : null;

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
      });
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ loading, error, isAuthenticated, currentUser }}>
      {children}
    </UserContext.Provider>
  );
};
