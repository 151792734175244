import { parse, parseISO, format, formatDistance } from "date-fns/fp";

export const FORMATS = {
  longDate: "MMMM d, yyyy",
  longDateTime: "MMMM d, yyyy 'at' h:mm bbb",
  isoDateOnly: "yyyy-MM-dd",
};

export const todayAtNoon = (): Date => {
  const d = new Date();
  d.setHours(12);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
};

export const isoDateStringToDate = parse(todayAtNoon())(FORMATS.isoDateOnly);

// YYYY-MM-DD
export type IsoDateString = string;

// useful for converting Date object for fields of graphene.Date
// e.g. '2020-11-04'
export const formatIsoDateOnly = (d: Date) => {
  const isoDateString = d.toISOString();
  return isoDateString.substring(0, isoDateString.indexOf("T"));
};

// e.g., 'April 3, 2020'
export const formatLongDate = format(FORMATS.longDate);

export const formatLongDateTime = format(FORMATS.longDateTime);

// e.g. 'April 3, 2020'
export const isoDateToLongFormat = (d: IsoDateString) => format(FORMATS.longDate)(parseISO(d));

export const formatTimeSince = (d: string) => `${formatDistance(parseISO(d), new Date())}`;
