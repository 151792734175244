import { useState, useCallback, useMemo } from "react";

type UseModal = {
  modalProps: { isOpen: boolean; onRequestClose: () => void };
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
};

export function useModal(initialState = false): UseModal {
  const [modalVisible, setModalVisible] = useState(initialState);

  const onRequestClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  return {
    modalProps: {
      isOpen: modalVisible,
      onRequestClose,
    },
    modalVisible,
    setModalVisible,
  };
}

type UseModalState<State> = {
  modalProps: { isOpen: boolean; onRequestClose: () => void; onAfterClose: () => void };
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  modalState: State;
  setModalState: (state: State) => void;
  setModalStateAndVisible: (state: State) => void;
};

export function useModalState<State>(
  initialState: State,
  resetState: State = initialState
): UseModalState<State> {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalState, setModalState] = useState<State>(initialState);

  const setModalStateAndVisible = useCallback((state: State) => {
    setModalState(state);
    setModalVisible(true);
  }, []);

  const onRequestClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onAfterClose = useCallback(() => {
    setModalState(resetState);
  }, [resetState]);

  const modalProps = useMemo(() => {
    return {
      isOpen: modalVisible,
      onRequestClose,
      onAfterClose,
    };
  }, [modalVisible, onRequestClose, onAfterClose]);

  return {
    modalProps,
    modalVisible,
    setModalVisible,
    modalState,
    setModalState,
    setModalStateAndVisible,
  };
}
