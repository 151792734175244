import { useFormikContext } from "@sugarliving/formik";
import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";
import Button, { ButtonProps } from "components/common/Button";

export const SubmitButton: React.FC<ButtonProps> = ({
  children,
  className,
  type,
  ...buttonProps
}) => {
  const formik = useFormikContext();
  return (
    <Button
      type="submit"
      className={cx(styles.submitButton, className)}
      loading={formik.isSubmitting}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
