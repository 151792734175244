import cx from "classnames";
import React from "react";

const alignments = {
  start: "items-start",
  center: "items-center",
  end: "items-end",
  baseline: "items-baseline",
  stretch: "items-stretch",
};

type Alignment = keyof typeof alignments;

const justifications = {
  start: "justify-start",
  center: "justify-center",
  end: "justify-end",
  between: "justify-between",
  around: "justify-around",
};

type Justification = keyof typeof justifications;

const getAlignItems = (align: Alignment) => alignments[align];
const getJustification = (justify: Justification) => justifications[justify];

export interface RowProps {
  align?: Alignment;
  justify?: Justification;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const Row = ({ align = "start", justify = "start", className, children }: RowProps) => {
  return (
    <div
      className={cx(
        "flex flex-row w-full",
        getAlignItems(align),
        getJustification(justify),
        className
      )}
    >
      {children}
    </div>
  );
};

export default Row;
