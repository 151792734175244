import React from "react";
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  RemoteTrackPublication,
  Participant,
  Track,
  LocalVideoTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";

import usePublications from "./hooks/usePublications";
import useTrack from "./hooks/useTrack";

type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

interface PublicationProps {
  className?: string;
  videoClassName?: string;
  publication: LocalTrackPublication | RemoteTrackPublication;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

const Publication = ({
  className,
  videoClassName,
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
}: PublicationProps) => {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          className={className}
          videoClassName={videoClassName}
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocalParticipant}
        />
      );
    case "audio":
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
};

interface ParticipantTracksProps {
  className?: string;
  videoClassName?: string;
  participant: Participant;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  className,
  videoClassName,
  participant,
  videoOnly,
  videoPriority,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

  // remove screen sharing publications
  const filteredPublications = publications.filter(p => !p.trackName.includes("screen"));

  return (
    <>
      {filteredPublications.map(publication => (
        <Publication
          key={publication.kind}
          className={className}
          videoClassName={videoClassName}
          publication={publication}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
