import React from "react";
import { useUID } from "react-uid";
import styles from "./SearchField.module.css";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import { ReactComponent as XIcon } from "assets/svg/x-icon.svg";
import { IconButton } from "components/common/Button";
import { Input, InputProps } from "components/common/Form/Input";
import { Label } from "components/common/Form/Label";

export interface SearchFieldProps extends InputProps {
  onRequestClear?: () => void;
}

const SearchField = ({ value, onRequestClear, ...props }: SearchFieldProps) => {
  const uid = useUID();

  return (
    <div className={styles.root}>
      <Label className={styles.label} htmlFor={uid}>
        Search
      </Label>
      <Input id={uid} className={styles.control} value={value} {...props} />
      <div className={styles.searchIconContainer}>
        {onRequestClear && typeof value === "string" && value.length > 0 ? (
          <IconButton onClick={onRequestClear} className="p-0">
            <XIcon height={14} width={12} />
          </IconButton>
        ) : (
          <SearchIcon />
        )}
      </div>
    </div>
  );
};

export default SearchField;
