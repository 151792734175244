export const GRAPHQL_HOST = process.env.REACT_APP_GRAPHQL_HOST || "http://localhost:5000";
export const GRAPHQL_BATCHING = true;

export const GRAPHQL_AUTH_ENDPOINT = GRAPHQL_BATCHING ? "/batch/auth/graphql/" : "/auth/graphql/";
export const GRAPHQL_UNAUTH_ENDPOINT = GRAPHQL_BATCHING ? "/batch/graphql/" : "/graphql/";

export const GRAPHQL_AUTH_URL = `${GRAPHQL_HOST}${GRAPHQL_AUTH_ENDPOINT}`;
export const GRAPHQL_UNAUTH_URL = `${GRAPHQL_HOST}${GRAPHQL_UNAUTH_ENDPOINT}`;

export const IS_SSR = typeof window === "undefined";
